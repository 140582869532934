import CaretIcon from '@/components/CaretIcon'
import { ButtonHTMLAttributes, FC, useRef } from 'react'
import IconButton from './IconButton'

export const ScrollTopButton: FC<{ isActive: number } & ButtonHTMLAttributes<HTMLButtonElement>> = ({ isActive, ...rest }) => {
  const isRendered = useRef<boolean>(!!isActive)
  if (isActive && !isRendered.current) {
    isRendered.current = true
  }
  if (!isRendered.current) {
    return null
  }
  return (
    <IconButton
      {...rest}
      className={`animate-fade-in-up ${
        isActive ? 'fixed bottom-24 right-8 h-10 w-10 !rounded-full p-2 shadow-md sm:right-12 xl:right-[calc(50%-690px)] 2xl:right-[calc(50%-720px)]' : 'hidden'
      }`}
      onClick={() => window?.scrollTo?.({ top: 0, behavior: 'smooth' })}
    >
      <CaretIcon contentOpen={true} className='h-full w-full text-current' />
    </IconButton>
  )
}
export default ScrollTopButton
